
import { mixins, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import OverlayPanel from "primevue/overlaypanel";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { capitalize } from "vue";
import { DeliveryManifestController } from "@/app/ui/controllers/DeliveryManifestController";
import {
  convertDecimal,
  convertDecimalAfterComma,
  convertDecimalWithComma,
  formatPrice,
  MAX_STT_DELIVERY_MANIFEST_UPDATE,
  removeDataLocalStorage,
  storeDatatoLocalStorage
} from "@/app/infrastructures/misc/Utils";
import OutGoingMixins from "@/app/ui/views/out-going-shipment/out-going-mixin";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { onPrintDeliveryManifest } from "../../modules/manifest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { TrackingController } from "@/app/ui/controllers/TrackingController";
import { TrackingSttData, TrackingSttDetailData } from "@/domain/entities/TrackingStt";

@Options({
  components: {
    DetailLayout,
    OverlayPanel
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess && !this.isPartial) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.setOpenSuccess(false);
      next();
    }
  }
})
export default class CreateFormDeliveryManifest extends mixins(OutGoingMixins) {
  pagination = {
    page: 1,
    limit: 50
  };
  mounted() {
    AccountController.checkAccountLocation();
    this.refs = this.$refs;
  }
  refs: any = "";

  // delete stt pieces
  sttNumberListTrash: Array<string> = [];
  showDeleteSttNumber = false;
  sttNumberToDelete = "";
  sttNumberSuccessDelete = "";
  errorSttNumberDelete = "";
  onDeleteSttNumber() {
    this.sttNumberToDelete = this.sttNumberToDelete.split("#")[0];
    if (this.sttNumberToDelete) {
      this.errorSttNumberDelete = "";
      this.sttNumberSuccessDelete = "";
      this.sttNumberToDelete = this.sttNumberToDelete.toUpperCase();

      let indexSttNumber = -1;
      indexSttNumber = this.dataSttToBeCreate.findIndex((data: any) => {
        return (
          data.sttNumber === this.sttNumberToDelete ||
          data.sttElexysNumber === this.sttNumberToDelete
        );
      });
      if (indexSttNumber < 0) {
        this.errorSttNumberDelete =
          "No. STT tidak ditemukan atau sudah terhapus";
      } else {
        this.dataSttToBeCreate.splice(indexSttNumber, 1);
        this.sttNumberListTrash.push(this.sttNumberToDelete);
        this.sttNumberSuccessDelete = this.sttNumberToDelete;
        this.sttNumberToDelete = "";
      }
    }
    const deleteStt: any = this.$refs.deleteStt;
    deleteStt?.focus();
  }
  onShowDelete() {
    this.errorSttNumberDelete = "";
    this.showDeleteSttNumber = true;
    this.sttNumberToDelete = "";
    this.sttNumberSuccessDelete = "";
    this.sttNumberListTrash = [];
  }

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }
  get detailDistrict() {
    return LocationController.districtDetail;
  }
  get typeUser() {
    return capitalize(this.dataProfile.account_type_detail.type);
  }

  // handle stt number
  isPaid = true;
  isUnpaidNotification = false;
  errorSttNumber = "";
  isErrorTyping = false;
  isErrorValidationScan = false;
  isPartial = false;
  countFailed = 0;
  countSuccess = 0;
  isScan = false;
  isMaxStt = false;
  get maxSttNumber() {
    return MAX_STT_DELIVERY_MANIFEST_UPDATE;
  }

  onCloseNotification() {
    this.isUnpaidNotification = false;
    this.notification = false;
  }

  async handleScanOthers(str: string) {
    MainAppController.showLoading();
    try {
      const resp = await TrackingController.trackStt({
        sttNo: str,
        isGetSla: false,
        isSecondCall: false,
        cache: true,
      });
      if (resp) {
        return {
          sttNo: TrackingController.trackingSttData.sttDetail.sttNo,
          noRefExternal: str,
        };
      } else {
        playNotification("error");
        this.errorSttNumber = "No. STT tidak ditemukan";
        this.isErrorValidationScan = true;
      }
    } finally {
      TrackingController.setTrackingSttData(
        new TrackingSttData(new TrackingSttDetailData(), [])
      );
      MainAppController.closeLoading();
    }
  }
   async handleValidationSTTOthers():Promise<{ sttNo: string; noRefExternal: string; }> {
    let sttNoOthers: any = { sttNo: "", noRefExternal: "" };
    const isSTT = this.checkIsSTT(this.form.sttNumber);
    if (!isSTT) {
      sttNoOthers = await this.handleScanOthers(this.form.sttNumber);
    }
    return sttNoOthers;
  }
  checkIsSTT(val: string) {
    return !!val.trim().match(/^\d{1,2}LP\w+$/g)
  }

  async addScanSttNumber(val: any) {
    if (!this.isDisableAddSttNumber) {
      this.isScan = true;
      const splitVal = val.split("#");
      if (splitVal.length > 0) this.form.sttNumber = splitVal[0];
      await this.onAddDetailStt();
    }
  }

  async addSttNumber() {
    if (!this.isDisableAddSttNumber) {
      this.isScan = false;
      await this.onAddDetailStt();
    }
  }

  async onAddDetailStt() {
    this.onCloseNotification();
    const sttNoOthers = await this.handleValidationSTTOthers();
    await DeliveryManifestController.getDetailDeliveryStt({
      sttOrBagNumber: !this.checkIsSTT(this.form.sttNumber) ? sttNoOthers?.sttNo : this.form.sttNumber,
      callback: (param: any, noRef: any) => {
        if (param === 'error') {
          MainAppController.closeErrorMessage();
          this.form.sttNumber = "";
          // re focus
          const inputStt: any = this.$refs.inputStt;
          inputStt.focus();
        }
        else this.addSttNumberToUpdate(param, noRef)
      },
      noRef: sttNoOthers?.noRefExternal
    });
  }

  isBlockDem = false;
  addSttNumberToUpdate(detailSTT: any, noRefExternal: string) {
    if (this.dataSttToBeCreate.length >= this.maxSttNumber) {
      this.isMaxStt = true;
    } else {
      if (detailSTT.isAllowUpdateStatus && detailSTT.piece !== null) {
        this.onValidateSttResults(detailSTT, noRefExternal);
      } else {
        playNotification("error");
        if (detailSTT.errorMessage === "STT ini sudah ditandai sebagai HAL" || detailSTT.errorMessage === "STT ini sudah ditandai sebagai RTS") {
          this.isBlockDem = true;
        } else {
          this.isErrorValidationScan = this.isScan;
          this.isErrorTyping = !this.isScan;
          this.isBlockDem = false;
          this.errorSttNumber = detailSTT.errorMessage || detailSTT.message.id;
        }
      }
    }
  }

  onValidateSttResults(detailSTT: any, noRefExternal: string) {
    const indexCurrentSttNumber = this.dataSttToBeCreate.findIndex(
      (data: any) => {
        return data.sttNumber === detailSTT.sttNumber;
      }
    );
    if (indexCurrentSttNumber > -1) {
      playNotification("error");
      this.errorSttNumber = "No. STT sudah di input";
      this.isErrorValidationScan = this.isScan;
      this.isErrorTyping = !this.isScan;
      return;
    } else {
      this.isPaid = detailSTT.isPaid;
      if (this.isPaid) {
        detailSTT.sttNoRefExternal = noRefExternal;
        this.dataSttToBeCreate.push(detailSTT);
        playNotification("success");
      } else {
        playNotification("error");
        this.isUnpaidNotification = true;
      }
    }
    this.detailSttNotification = {
      sttNumber: detailSTT.sttNumber,
      totalPieces: detailSTT.sttTotalAmount,
      sttElexysNumber: detailSTT.sttElexysNumber,
      ...detailSTT
    };

    // reset form
    this.form.sttNumber = "";
    this.isScan = false;
    this.isErrorTyping = false;
    this.isErrorValidationScan = false;
    this.notification = true;
    // re focus
    const inputStt: any = this.$refs.inputStt;
    inputStt.focus();

    // remove notification after 15 second
    if (this.isPaid) {
      setTimeout(() => {
        this.notification = false;
      }, 15000);
    }
  }

  get totalStt() {
    return this.dataSttToBeCreate.length;
  }
  get calculatedStt() {
    return this.dataSttToBeCreate.reduce(
      (a: any, b: any) => {
        return {
          totalPieces: a.totalPieces + b.sttTotalPiece,
          totalGrossWeight: a.totalGrossWeight + b.grossWeight,
          totalVolumeWeight: a.totalVolumeWeight + b.volumeWeight
        };
      },
      {
        totalPieces: 0,
        totalGrossWeight: 0,
        totalVolumeWeight: 0
      }
    );
  }
  get totalPieces() {
    return this.calculatedStt.totalPieces;
  }
  get totalGrossWeight() {
    return this.calculatedStt.totalGrossWeight;
  }
  get totalVolumeWeight() {
    return this.calculatedStt.totalVolumeWeight;
  }

  // form model
  form = {
    date: new Date(),
    sttNumber: ""
  };

  get isDisableAddSttNumber() {
    return !this.form.sttNumber || this.totalStt >= 50;
  }

  // Origin Transit City
  get originCity() {
    return `${this.dataProfile.account_location.city_code} - ${this.dataProfile.account_location.city_name}`;
  }

  // convert value decimal
  isValueDecimal(value: number): number | string {
    return convertDecimal(value);
  }

  // format total kg
  convertTotalKg(value: number) {
    return convertDecimalAfterComma(value, 1);
  }

  // panel pickup
  togglePanel(event: any) {
    const refs: any = this.$refs;
    refs.op?.toggle(event);
  }

  // notification handler
  notification = false;
  detailSttNotification: any = null;

  // table
  sourceSttFromLocalStorage: any[] = JSON.parse(
    localStorage.getItem("delivery-manifest") || "[]"
  );
  get dataSttToBeCreate() {
    storeDatatoLocalStorage(
      "delivery-manifest",
      this.sourceSttFromLocalStorage
    );
    return this.sourceSttFromLocalStorage;
  }
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-10 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: any, index: number) => {
          return `<div class="text-black-lp-300 pt-2.5">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: `No. STT`,
        styleHead: "w-44 text-left whitespace-no-wrap",
        styleCustom: "deliveryManifestUpdateTable",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${item.sttNumber}</div>`;
        }
      },
      {
        name: `No. Referensi`,
        styleHead: "w-44 text-left whitespace-no-wrap",
        styleCustom: "deliveryManifestUpdateTable",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${item.sttNoRefExternal || "-"}</div>`;
        }
      },
      {
        name: "Nama Penerima",
        styleHead: "w-52 text-left whitespace-no-wrap",
        styleCustom: "deliveryManifestUpdateTable",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${item.recipientName}</div>`;
        }
      },
      {
        name: "Alamat Penerima",
        styleHead: "alamatDeliveryManifest text-left whitespace-no-wrap",
        styleCustom: "deliveryManifestUpdateTable",
        render: (item: any) => {
          return `<div class="text-black-lp-300 break-all">${item.recipientAddress}</div>`;
        }
      },
      {
        name: "No. Telp Penerima",
        styleHead: "w-56 text-left whitespace-no-wrap",
        styleCustom: "deliveryManifestUpdateTable",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${item.recipientPhone}</div>`;
        }
      },
      {
        name: "Produk",
        styleHead: "w-32 text-left whitespace-no-wrap",
        styleCustom: "deliveryManifestUpdateTable",
        render: (item: any) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.product}
                  </div>
                </div>`;
        }
      },
      {
        name: "Komoditas",
        styleHead: "w-48 text-left whitespace-no-wrap",
        styleCustom: "deliveryManifestUpdateTable",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${item.commodity}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-40 text-left whitespace-no-wrap",
        styleCustom: "deliveryManifestUpdateTable",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${item.sttTotalPiece}</div>`;
        }
      },
      {
        name: "Berat Kotor",
        styleHead: "w-36 text-left whitespace-no-wrap",
        styleCustom: "deliveryManifestUpdateTable",
        render: (item: any) => {
          return `<div class="text-black-lp-300 flex">
                      ${convertDecimalWithComma(item.grossWeight, 2)} Kg
                </div>`;
        }
      },
      {
        name: "Berat Dimensi",
        styleHead: "w-36 text-left whitespace-no-wrap",
        styleCustom: "deliveryManifestUpdateTable",
        render: (item: any) => {
          return `<div class="text-black-lp-300 flex">
                      ${convertDecimalWithComma(item.volumeWeight, 2)} Kg
                </div>`;
        }
      },
      {
        name: "Berat Dikenakan Biaya",
        styleHead: "w-56 text-left whitespace-no-wrap",
        styleCustom: "deliveryManifestUpdateTable",
        render: (item: any) => {
          return `<div class="text-black-lp-300 flex">
                      ${convertDecimalWithComma(item.chargeAbleWeight, 2)} Kg
                </div>`;
        }
      },
      {
        name: "Pengiriman Ke",
        styleHead: "w-36 text-left whitespace-no-wrap",
        styleCustom: "deliveryManifestUpdateTable",
        render: (item: any) => {
          return `<div class="text-black-lp-300 flex">
                      ${item.deliveryCount}
                </div>`;
        }
      },
      {
        name: "Biaya COD",
        styleHead: "w-44 text-left whitespace-no-wrap",
        styleCustom: "deliveryManifestUpdateTable",
        render: (item: any) => {
          return `<div class="text-black-lp-300 flex">
                      ${item.codAmount ? formatPrice(item.codAmount) : "-"}
                </div>`;
        }
      }
    ];
  }

  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  get listSttNumber() {
    return this.dataSttToBeCreate.map(e => {
      return e.sttNumber;
    });
  }

  withPrint = false;
  async onSave() {
    this.handleConfirmationSave(false);
    const respUpdateDelivery = await DeliveryManifestController.updateDelivery({
      destinationCityId: this.dataProfile.account_location.city_code,
      sttNo: this.listSttNumber
    });
    if (respUpdateDelivery.data) {
      if (this.withPrint && respUpdateDelivery.data.totalSttSuccess > 0) {
        onPrintDeliveryManifest(
          respUpdateDelivery.data.deliveryManifestId,
          "manifest"
        );
      }
      if (
        respUpdateDelivery.data.totalSttFailed > 0 &&
        respUpdateDelivery.data.totalSttSuccess === 0
      ) {
        this.countFailed = respUpdateDelivery.data.totalSttFailed;
        this.setFailed(true);
        DeliveryManifestController.setSttFailedUpdate(
          respUpdateDelivery.data.sttFailed
        );
      } else if (
        respUpdateDelivery.data.totalSttFailed > 0 &&
        respUpdateDelivery.data.totalSttSuccess > 0
      ) {
        this.countFailed = respUpdateDelivery.data.totalSttFailed;
        this.countSuccess = respUpdateDelivery.data.totalSttSuccess;
        DeliveryManifestController.setSttFailedUpdate(
          respUpdateDelivery.data.sttFailed
        );
        this.isPartial = true;
      } else {
        this.setOpenSuccess(true);
      }
      this.sourceSttFromLocalStorage = [];
    }
  }

  // dummy stt failed
  get sttFailedToUpdate() {
    return DeliveryManifestController.sttFailedUpdate;
  }

  createAndPrint(withPrint: boolean) {
    const refs: any = this.$refs;
    refs.op?.hide();
    this.withPrint = withPrint;
    this.handleConfirmationSave(true);
  }

  // handle route leave and modal
  // navigation
  get openSuccess(): boolean {
    return DeliveryManifestController.isOpenSuccess;
  }
  setOpenSuccess(value: boolean) {
    DeliveryManifestController.setOpenSuccess(value);
  }

  // failed for create
  get isFailed(): boolean {
    return DeliveryManifestController.isFailed;
  }
  setFailed(value: boolean) {
    DeliveryManifestController.setFailed(value);
  }

  openConfirmationLeave = false;
  openConfirmationSave = false;
  answer = false;
  newPath = "";
  handleConfirmationLeave(value: boolean) {
    this.openConfirmationLeave = value;
  }
  handleConfirmationSave(value: boolean) {
    this.openConfirmationSave = value;
  }
  goBack() {
    this.$router.push("/delivery-manifest");
  }
  onOpenClosePage(to: any) {
    this.handleConfirmationLeave(true);
    this.newPath = to.path;
  }
  handlerClose() {
    removeDataLocalStorage("delivery-manifest");
    this.handleConfirmationLeave(false);
    this.answer = true;
    this.$router.push(this.newPath);
  }
  handlerCancelClose() {
    this.answer = false;
    this.handleConfirmationLeave(false);
  }
  onCloseSuccess() {
    this.goBack();
  }

  // error message
  onResetIsAlreadyExist() {
    this.form.sttNumber = "";
    this.errorSttNumber = "";
    this.isErrorTyping = false;
    this.isErrorValidationScan = false;
    // re focus
    const inputStt: any = this.$refs.inputStt;
    inputStt.focus();
  }
  onDownloadCsvFailed() {
    this.downloadCsvFailedStt({
      fileName: "delivery_manifest_stt_failed.csv",
      listStt: this.sttFailedToUpdate
    });
  }
  get alertPopup() {
    // if success
    if (this.openSuccess)
      return {
        onClick: () => this.onCloseSuccess(),
        title: "Pembuatan Berhasil !",
        message: "Delivery Manifest berhasil dibuat.",
        image: "image-modal-success"
      };
    // if error typing not found
    if (this.isErrorTyping)
      return {
        onClick: () => this.onResetIsAlreadyExist(),
        title: this.errorSttNumber.toLowerCase().includes("stt gagal discan karena paket akan diberi status pod setelah diambil oleh penerima.") ? "Paket akan diambil penerima" : this.isScan ? "Scan No. STT Gagal" : "Tambah No. STT Gagal",
        message: this.errorSttNumber,
        image: this.errorSttNumber.toLowerCase().includes("stt gagal discan karena paket akan diberi status pod setelah diambil oleh penerima.") ? "circle-error" : "image-modal-failed",
        isTextBtnConfirmH2H: this.errorSttNumber.toLowerCase().includes("stt gagal discan karena paket akan diberi status pod setelah diambil oleh penerima.")
      };
    // if input by scan
    else if (this.isErrorValidationScan)
      return {
        onClick: () => this.onResetIsAlreadyExist(),
        title: this.errorSttNumber.toLowerCase().includes("stt gagal discan karena paket akan diberi status pod setelah diambil oleh penerima.") ? "Paket akan diambil penerima" : "Scan No. STT Gagal",
        message: this.errorSttNumber,
        image: this.errorSttNumber.toLowerCase().includes("stt gagal discan karena paket akan diberi status pod setelah diambil oleh penerima.") ? "circle-error" : "image-modal-failed",
        isTextBtnConfirmH2H: this.errorSttNumber.toLowerCase().includes("stt gagal discan karena paket akan diberi status pod setelah diambil oleh penerima.")
      };
    // if error typing not found
    if (this.isPartial)
      return {
        onClick: () => this.onCloseSuccess(),
        onDownload: () => this.onDownloadCsvFailed(),
        title: "Pembuatan Berhasil !",
        message:
          "Beberapa No. STT berhasil dibuat menjadi Delivery Manifest,\nnamun terdapat kegagalan pada beberapa STT.\nSilahkan download untuk mengetahui kesalahan.",
        image: "image-modal-warning"
      };
    // default is failed
    return {
      onClick: () => this.setFailed(false),
      onDownload: () => this.onDownloadCsvFailed(),
      title: "Pembuatan Gagal !",
      message: "Delivery Manifest gagal dibuat.",
      image: "image-modal-failed"
    };
  }

  closeBlockDem() {
    this.isBlockDem = false;
  }
}
